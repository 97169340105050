import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Logo() {
	const data = useStaticQuery(graphql`
		{
			markdownRemark(frontmatter: { layout: { eq: "logo" } }) {
				frontmatter {
					logo_alt
					logo {
						childImageSharp {
							gatsbyImageData(backgroundColor: "transparent", placeholder: NONE)
						}
					}
				}
			}
		}
	`);
	const { markdownRemark } = data;
	const { frontmatter: page } = markdownRemark;
	const { logo_alt, logo } = page;
	const image = getImage(logo);
	return (
		<GatsbyImage
			image={image}
			alt={logo_alt}
			className="logo"
			backgroundColor="transparent"
		/>
	);
}
