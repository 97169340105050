import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { device } from "../Device/Device";
import { Link } from "gatsby";
import Logo from "../Shared/Logo";

const NavigationWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
	background: ${({ navbar }) => (navbar ? "rgb(71,188,202)" : "transparent")};
	transition: all 0.3s linear;
`;
const LogoTop = styled(Link)`
	transition: all 1s ease-in-out;
	padding: ${({ navbar }) => (navbar ? "10px 0" : "0")};

	.logo {
		height: 100%;
		margin-top: ${({ navbar }) => (navbar ? "0" : "0")};
		margin-left: ${({ navbar }) => (navbar ? "0" : "0")};
		width: ${({ navbar }) => (navbar ? "64px" : "92px")};
	}

	@media ${device.laptop} {
		flex-basis: 60%;
		padding: 10px 0;
		.logo {
			margin-top: 0;
			margin-left: 0;
			width: 100%;
			max-width: 150px;
		}
	}
`;
const Navbar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 5% 0 5%;

	@media ${device.laptop} {
		align-items: center;
		padding: 0 5%;
	}
`;
const MenuIcon = styled.button`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 2rem;
	height: 2rem;
	background: transparent;
	border: none;
	cursor: pointer;
	z-index: 5;

	div {
		width: 2rem;
		height: 0.2rem;
		/* background: ${({ nav }) => (nav ? "#fff" : "rgb(71,188,202)")}; */
		background: #fff;
		border-radius: 5px;
		transform-origin: 1px;
		position: relative;
		/* transition: opacity 300ms, transform 300ms ; */
		transition: all 0.3s linear;

		:first-child {
			transform: ${({ nav }) => (nav ? "rotate(45deg)" : "rotate(0)")};
		}
		:nth-child(2) {
			opacity: ${({ nav }) => (nav ? "0" : "1")};
			transform: ${({ nav }) => (nav ? "translateX(20px)" : "translateX(0)")};
		}
		:nth-child(3) {
			opacity: ${({ nav }) => (nav ? "0" : "1")};
			transform: ${({ nav }) => (nav ? "translateX(20px)" : "translateX(0)")};
		}
		:nth-child(4) {
			opacity: ${({ nav }) => (nav ? "0" : "1")};
			transform: ${({ nav }) => (nav ? "translateX(20px)" : "translateX(0)")};
		}
		:nth-child(5) {
			transform: ${({ nav }) => (nav ? "rotate(-45deg)" : "rotate(0)")};
		}
	}
	@media ${device.laptop} {
		display: none;
	}
`;
const MenuLinks = styled.nav`
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: flex-start;
	text-align: center;
	width: 50%;
	height: ${({ navbar }) => (navbar ? "100vh" : "75vh")};
	position: absolute;
	top: 0;
	right: 0;
	transition: transform 300ms;
	transform: ${({ nav }) => (nav ? "tarnslateX(0)" : "translateX(100%)")};
	background: rgb(71, 188, 202);
	padding-top: 80px;
	padding-left: 15px;
	padding-right: 15px;

	ul {
		list-style-type: none;
	}
	li {
		margin-top: 1.5rem;
		white-space: nowrap;
	}

	a {
		text-decoration: none;
		padding: 20px 0;
		color: #fff;
		transition: color 300ms;
		font-family: "Montserrat", sans-serif;
		font-weight: 400;
		text-transform: uppercase;

		&:hover {
			/* filter: brightness(2); */
			color: ${({ navbar }) => (navbar ? "#fff" : "rgb(71,188,202)")};
		}
	}
	@media ${device.laptop} {
		position: unset;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		transform: translateX(0);
		background: transparent;
		height: 100%;
		padding: 0;
		width: 100%;
		li {
			margin: 0;
		}
		a {
			font-size: clamp(0.7rem, 1vw + 0.15rem, 2.5rem);
		}
	}
`;

function Navigation() {
	const [nav, showNav] = useState(false);
	const [navbar, setNavbar] = useState(false);
	const navbarItemsArray = [
		{
			name: "O nas",
			link: "/#about",
		},
		{
			name: "Jak pomagamy",
			link: "/#services",
		},
		{
			name: "co oferujemy",
			link: "/#project",
		},
		{
			name: "Zespół",
			link: "/#team",
		},
		{
			name: "Blog",
			link: "/#blog",
		},
		{
			name: "Klienci",
			link: "/#custom",
		},
		{
			name: "Kontakt",
			link: "/#contact",
		},
		{
			name: "Sklep",
			link: "https://estesklep.pl/",
		},
	];
	const changeBackground = () => {
		if (window.scrollY >= 50) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};
	useEffect(() => {
		changeBackground();
		window.addEventListener("scroll", changeBackground);
	});

	return (
		<NavigationWrapper
			navbar={navbar}
			changeBackground={() => setNavbar(!navbar)}
		>
			<Navbar>
				<LogoTop
					id="logo"
					navbar={navbar}
					changeBackground={() => setNavbar(!navbar)}
					to="/"
				>
					<Logo />
				</LogoTop>

				<MenuIcon
					className="nav-btn"
					nav={nav}
					onClick={() => showNav(!nav)}
					navbar={navbar}
					changeBackground={() => setNavbar(!navbar)}
				>
					<div />
					<div />
					<div />
					<div />
					<div />
				</MenuIcon>

				<MenuLinks
					nav={nav}
					onClick={() => showNav(false)}
					navbar={navbar}
					changeBackground={() => setNavbar(!navbar)}
				>
					{navbarItemsArray.map((item) => {
						return (
							<ul>
								<li>
									<Link
										to={item.link}
										className="nav-link"
										navbar={navbar}
										onMouseOver={() => setNavbar(false)}
									>
										{item.name}
									</Link>
								</li>
							</ul>
						);
					})}
				</MenuLinks>
			</Navbar>
		</NavigationWrapper>
	);
}

export default Navigation;
