import { useStaticQuery, graphql } from "gatsby";

export const useCompanyDetails = () => {
	const { markdownRemark } = useStaticQuery(
		graphql`
			{
				markdownRemark(frontmatter: { layout: { eq: "company_contact" } }) {
					frontmatter {
						company_name
						company_address
						company_city
						company_area_code
						company_phone
						company_phone_second
						company_email
						nip
						regon
						krs
						linkedin
						facebook
						instagram
						youtube
						shop
					}
				}
			}
		`
	);

	return markdownRemark?.frontmatter;
};
