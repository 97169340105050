import React from "react";
import styled from "styled-components";
import { device } from "../Device/Device";
import Social from "../Navigation/Social";
import { useCompanyDetails } from "../utils/companyDetails/useCompanyDetails";
import Logo from "../Shared/Logo";

const Wrapper = styled.footer`
	background: #47bcca;
	overflow-x: hidden;
	color: #fff;
	display: flex;
	flex-direction: column;
	.footer-upper {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 30px 0;
	}
	.descwrapper {
		display: flex;
		flex-direction: column;
	}

	.logo {
		width: 200px;
		cursor: pointer;
		margin: 0 0 30px;
	}

	.topic-block {
		padding: 15px 0;
		> p > a {
			color: #fff;
		}
	}

	.information {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 15px 0;
		> p > a {
			color: #fff;
		}
	}

	@media ${device.laptop} {
		.footer-upper {
			flex-direction: row;
		}
		.descwrapper {
			flex-basis: 100%;
			flex-direction: row;
			justify-content: space-between;
		}

		.logo {
			width: 280px;
			height: 100%;
			align-self: center;
			margin-right: 100px;
		}
		.topic-block {
			margin-right: 30px;
		}
		.topic-block h3 {
			font-size: clamp(0.875rem, 0.1981rem + 1.0577vw, 1.15rem);
		}
		.topic-block p,
		.information p {
			font-size: clamp(0.8rem, 0.3077rem + 0.7692vw, 1rem);
		}
		a {
			color: #fff;
		}
	}
`;

const Footer = () => {
	const page = useCompanyDetails();
	const {
		company_name,
		company_address,
		company_city,
		company_area_code,
		company_phone,
		company_phone_second,
		nip,
		regon,
		krs,
		shop,
	} = page;

	return (
		<Wrapper className="globalWrapper">
			<div className="footer-upper">
				<Logo />

				<div className="descwrapper">
					<div className="topic-block">
						<h3>{company_name}</h3>
						<p>{company_address}</p>
						<p>
							{company_area_code} {company_city}
						</p>
						<p>
							<a href={`tel:${company_phone_second}`}>{company_phone_second}</a>
						</p>
					</div>
					<div className="topic-block">
						<h3>Telefony:</h3>
						<p>
							Este Sklep/Biuro:{" "}
							<a href={`tel:${company_phone_second}`}>{company_phone_second}</a>
						</p>
						<p>Szkolenia/urządzenia/rekrutacja:</p>
						<p>
							Magdalena Sowa{" "}
							<a href={`tel:${company_phone}`}>{company_phone} </a>
						</p>
					</div>
					<div className="information">
						<p>KRS: {krs}</p>
						<p>NIP: {nip}</p>
						<p>REGON: {regon}</p>
						<p>
							<a href={shop}>www.estesklep.pl</a>
						</p>
					</div>
				</div>
			</div>
			<div className="bottom globalWrapper">
				<Social />
			</div>
		</Wrapper>
	);
};

export default Footer;
