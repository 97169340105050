import React from 'react'
import { RiLinkedinFill } from "@react-icons/all-files/ri/RiLinkedinFill"
import { RiFacebookFill } from "@react-icons/all-files/ri/RiFacebookFill"
import { RiYoutubeFill } from "@react-icons/all-files/ri/RiYoutubeFill"
import { RiInstagramFill } from "@react-icons/all-files/ri/RiInstagramFill"
import styled from 'styled-components'
import { device } from '../Device/Device'
import { useCompanyDetails } from "../utils/companyDetails/useCompanyDetails";

const SocialWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	padding: 15px 0;
	& a {
		padding: 0 10px;
		color: #fff;
		&:first-of-type {
			padding: 0 10px 0 0;
		}
		& svg {
			width: 30px;
			height: 30px;
		}
	}
	@media ${device.laptop} {
	}
`;
const Social = () => {
	const page = useCompanyDetails();
	const { facebook, instagram, youtube, linkedin } = page;

	return (
		<SocialWrapper className="social">
			<a
				target="_blank"
				rel="noreferrer noopener"
				href="https://www.linkedin.com/company/19195006"
			>
				<span>
					<RiLinkedinFill />
				</span>
			</a>
			<a target="_blank" rel="noreferrer noopener" href={facebook}>
				<span>
					<RiFacebookFill />
				</span>
			</a>
			<a target="_blank" rel="noreferrer noopener" href={youtube}>
				<span>
					<RiYoutubeFill />
				</span>
			</a>
			<a target="_blank" rel="noreferrer noopener" href={instagram}>
				<span>
					<RiInstagramFill />
				</span>
			</a>
		</SocialWrapper>
	);
};

export default Social
